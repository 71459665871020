import { Carousel, Box } from "components"
import { compose, withHooks, withTranslation } from "enhancers"
import { PageContent } from "layouts"
import { InsuranceItem, InsuranceItemProps } from "./InsuranceItem"
import { TFunction } from "i18next"
import { formatDate, gql } from "utils/helper"
import { EnrollmentState as EnumEnrollmentState } from "constants/enums/enrollment-state"
import { EmptyInsuranceItem } from "./EmptyInsuranceItem"
import { isEmpty } from "lodash"
import Loader from "components/Loader"
import { EnumAttendeeState } from "constants/enums/attendee-state"

interface HomeComponentProps {
  t: TFunction
  loading: boolean

  insuranceItems: Omit<InsuranceItemProps, "t" | "handleClickButton">[]
  currentYearSelectedIndex: number
}

const HomeComponent = (props: HomeComponentProps) => {
  return (
    <>
      {props.loading ? (
        <Loader />
      ) : (
        <PageContent title={props.t(".title")} childrenPadding="0px" childrenMarginTop="-80px">
          {props.insuranceItems.length === 0 ? (
            <Box mt={4}>
              <EmptyInsuranceItem />
            </Box>
          ) : (
            <Carousel
              sliderItems={props.insuranceItems.map((insuranceItem) => (
                <InsuranceItem
                  id={insuranceItem.id}
                  type={insuranceItem.type}
                  year={insuranceItem.year}
                  attendeeEnrollmentEndDate={insuranceItem.attendeeEnrollmentEndDate}
                  protectionEndDate={insuranceItem.protectionEndDate}
                />
              ))}
              showDots
              defaultIndex={props.currentYearSelectedIndex}
            />
          )}
        </PageContent>
      )}
    </>
  )
}

const API = {
  GET_APP_USER_ENROLLMENT: gql`
    query GET_APP_USER_ENROLLMENT {
      appUserEnrollment {
        id
        attendeeId
        year
        state
        startDate
        endDate
        attendeeState
        masterInsurance {
          protectionEndDate
        }
      }
    }
  `,
}

const enhancer = compose(
  withTranslation({ prefix: "pages.main.home.index" }),
  withHooks((props: any, hooks: any) => {
    const { useMemo, useQuery } = hooks
    const { data, loading } = useQuery(API.GET_APP_USER_ENROLLMENT, { fetchPolicy: "network-only" })
    const insuranceItems: Omit<InsuranceItemProps, "t" | "handleClickButton">[] = useMemo((): Omit<
      InsuranceItemProps,
      "t" | "handleClickButton"
    >[] => {
      let enrollments = data?.appUserEnrollment
        .map(
          (enrollment: any): Omit<InsuranceItemProps, "t" | "handleClickButton"> => {
            const endDate = new Date(enrollment.endDate)
            endDate.setHours(23, 59)

            const protectionEndDate = new Date(enrollment.masterInsurance.protectionEndDate)
            protectionEndDate.setHours(23, 59)

            console.log({ protectionEndDate })

            const expired = enrollment.attendeeState === EnumAttendeeState.closed
            // const expired = Date.now() > endDate.getTime()
            return {
              id: enrollment.attendeeId,
              year: enrollment.year,
              attendeeEnrollmentEndDate: endDate,
              protectionEndDate,
              type: expired ? "info" : "warning",
              state: enrollment.state,
            }
          },
        )
        .sort((a: { state: string; year: number }, b: { year: number }) => {
          // if (a.state !== EnrollmentState.closed) {
          //   return a.year - b.year
          // } else {
          return a.year - b.year
          // }
        })
      return enrollments || []
    }, [data])

    const currentYearSelectedIndex = useMemo(() => {
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()

      const enrollmentObjects: {
        [key: number]: Omit<InsuranceItemProps, "t" | "handleClickButton"> & { index: number }
      } = {}
      for (const [index, enrollment] of insuranceItems.entries()) {
        enrollmentObjects[enrollment.year] = {
          ...enrollment,
          index,
        }
      }
      const currentYearEnrollment = enrollmentObjects[currentYear]

      // Case 1 "โชว์ปีปัจจุบันที่เปิดอยู่"
      // if (
      //   !isEmpty(currentYearEnrollment) &&
      //   [EnumEnrollmentState.active, EnumEnrollmentState.published].includes(
      //     currentYearEnrollment.state as EnumEnrollmentState,
      //   )
      // ) {
      //   console.log("Case 1")

      //   return currentYearEnrollment.index
      // }

      // Case 2 "โชว์ปีที่น้อยที่สุดที่ยังเปิดอยู่"
      const openingEnrollments = insuranceItems.filter((enrollment: any) =>
        [EnumEnrollmentState.active, EnumEnrollmentState.published].includes(enrollment.state),
      )
      if (openingEnrollments.length > 0) {
        console.log("Case 2")
        return insuranceItems.findIndex((enrollment: any) =>
          [EnumEnrollmentState.active, EnumEnrollmentState.published].includes(enrollment.state),
        )
      } else {
        // Case 3 "ถ้าปิดหมด"
        if (isEmpty(currentYearEnrollment)) {
          // Case 3.1 "ถ้าปิดหมดแล้ว ไม่มีปีปัจจุบัน"
          for (let n = 1; n <= 5; n++) {
            // เอาปีน้อยสุดก่อน
            const lastNYear = currentYear - n
            const lastNYearEnrollment = enrollmentObjects[lastNYear]
            if (!isEmpty(lastNYearEnrollment)) {
              console.log("Case 3.1-1")
              return lastNYearEnrollment.index
            }

            // เอาปีมากที่ใกล้เคียง
            const futureNYear = currentYear + n
            const futureNYearEnrollment = enrollmentObjects[futureNYear]
            if (!isEmpty(futureNYearEnrollment)) {
              console.log("Case 3.1-2")
              return futureNYearEnrollment.index
            }
          }
        } else {
          // Case 3.2 "ถ้าปิดหมดแล้ว มีปีปัจจุบัน"
          console.log("Case 3.2")
          return currentYearEnrollment.index
        }
      }

      return 0
    }, [insuranceItems])

    return {
      loading,

      insuranceItems,
      currentYearSelectedIndex,
    }
  }),
)

export const HomePage = enhancer(HomeComponent)
