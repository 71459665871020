import { Button, Modal, Typography } from "components"
import { Checkbox } from "@material-ui/core"
import styled from "styled-components"
import Theme from "theme/custom"
import { TFunction } from "i18next"
import { compose, withHooks, withTranslation } from "enhancers"
import { MaterialIcon } from "components/common/MaterialIcon"
import { useTranslation } from "react-i18next"
import { useCallback } from "hooks"

const Content = styled("div")`
  background-color: ${Theme.colors["White / White"]};
  max-height: 680px;
  max-width: 330px;
  position: relative;
`

const Header = styled("header")`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  margin-bottom: 7px;
  .closeBtn {
    border: none;
    position: absolute;
    right: 0px;
  }
`

const Main = styled("div")`
  overflow: scroll;
  color: ${Theme.colors["Gray/Secondary Text"]};
  height: 500px;
  ul {
    padding-inline-start: 20px;
    margin-inline-end: 26px;
  }
  li {
    margin: 0px;
    list-style-type: decimal;
    margin-bottom: 7px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`

const Footer = styled("div")`
  margin-top: 10px;
  background-color: ${Theme.colors["White / White"]};
`
const GroupBtn = styled("div")`
  height: 100%;
  display: flex;
  ่justify-content: center;
  text-align: center;
  .cancelBtn {
    width: 60%;
    margin-right: 10px;
  }
  .acceptBtn {
    width: 60%;
  }
  .acceptBtn:hover {
    opacity: 0.8;
  }
  Button:disabled,
  Button[disabled] {
    color: #ffffff;
    background-color: ${Theme.colors["Primary/Disable"]};
  }
`
const CheckboxGroup = styled("div")`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  .isActive {
    opacity: 1;
  }
  .inActive {
    opacity: 0.5;
  }
`

const CustomCheckbox = styled(Checkbox)({
  "&.MuiCheckbox-colorSecondary.Mui-checked": {
    color: `${Theme.colors["Primary/Primary Text"]}`,
  },
  "&.Mui-disabled": {
    opacity: 0.5,
  },
  "&.MuiButtonBase-root.MuiIconButton-root": {
    padding: 0,
    marginRight: "8px",
    color: `${Theme.colors["Primary/Primary Text"]}`,
  },
})

interface ConsentProps {
  t: TFunction
  acceptCheckBox: boolean
  showCheckbox: boolean
  consentRule: string[]
  acceptConsent: () => void
  handleCheckbox: () => void
  closeModal: () => void
  onSubmit: () => void
  check: boolean
}

const ConsentComponent = (props: ConsentProps) => (
  <>
    <Content>
      <Header>
        <Typography className="title" variant="Header/16">
          {props.t(".header")}
        </Typography>
        <Button
          className="closeBtn"
          color={Theme.colors["Primary/Primary Text"]}
          variant="text"
          onClick={props.closeModal}
        >
          <MaterialIcon name="Close" fontSize="small" />
        </Button>
      </Header>
      <Main id="scroll-zone">
        <Typography className="title" variant="Body/14">
          {props.t(".title")}
        </Typography>
        <ul>
          {props.consentRule.map((item, index) => {
            return (
              <Typography variant="Body/14">
                <li key={index}>
                  <Typography variant="Body/14">{item}</Typography>
                </li>
              </Typography>
            )
          })}
        </ul>
        <Typography variant="Body/14">{props.t(".footer")}</Typography>
      </Main>
      <Footer>
        <CheckboxGroup>
          <CustomCheckbox
            size="small"
            disabled={!props.showCheckbox}
            onChange={props.handleCheckbox}
            checked={props.acceptCheckBox}
          />
          <Typography className={props.showCheckbox ? "isActive" : "inActive"} variant="Body/16">
            {props.t(".acceptConsent")}
          </Typography>
        </CheckboxGroup>
        <GroupBtn>
          <Button className="cancelBtn" variant="outlined" onClick={props.closeModal}>
            <Typography variant="Subtitle/16">{props.t(".cancel")}</Typography>
          </Button>
          <Button
            className="acceptBtn"
            variant="contained"
            onClick={props.acceptConsent}
            disabled={!props.acceptCheckBox || props.check}
          >
            <Typography variant="Subtitle/16">{props.t(".accept")}</Typography>
          </Button>
        </GroupBtn>
      </Footer>
    </Content>
  </>
)

const enhancer = compose(
  withTranslation({ prefix: "pages.main.benefit.Consent" }),
  withHooks(
    (props: any, hooks: any): Omit<ConsentProps, "t"> => {
      const { useState, useEffect } = hooks
      const { onSubmit, t } = props
      const { i18n } = useTranslation()
      const { language, changeLanguage } = i18n
      const [acceptCheckBox, setAcceptCheckBox] = useState(false)
      const [showCheckbox, setShowCheckbox] = useState(false)
      const [isButtonPressed, setIsButtonPressed] = useState(false)
      const consentRule = [
        t("client.consentRule.1"),
        t("client.consentRule.2"),
        t("client.consentRule.3"),
        t("client.consentRule.4"),
        t("client.consentRule.5"),
        t("client.consentRule.6"),
        t("client.consentRule.7"),
        t("client.consentRule.8"),
      ]

      // TODO: remove this code after fix invalid showCheckbox calculate state
      useEffect(() => {
        setShowCheckbox(true)
      }, [])

      // TODO: uncomment this process after fix invalid showCheckbox state problem
      // useEffect(() => {
      //   const mainScroll = document.querySelector("#scroll-zone")
      //   const screenWidth = window.screen.width
      //   const absoluteTop = handleWidth(language, screenWidth)

      //   mainScroll?.addEventListener("scroll", () => {
      //     if (mainScroll?.scrollTop > absoluteTop) {
      //       setShowCheckbox(true)
      //     } else {
      //       clearState()
      //     }
      //   })
      //   return () => {
      //     mainScroll?.removeEventListener("scroll", () => {
      //       if (mainScroll?.scrollTop > absoluteTop) {
      //         setShowCheckbox(true)
      //       } else {
      //         clearState()
      //       }
      //     })
      //   }
      // })

      const acceptConsent = async () => {
        await onSubmit()
        setIsButtonPressed(true)
        closeModal()
      }

      const handleWidth = useCallback((lang: string, width: number) => {
        switch (lang) {
          case "th":
            return width > 375 ? 500 : 600
          case "en":
            return width > 375 ? 720 : 985
          default:
            return 650
        }
      }, [])

      const handleCheckbox = async () => {
        setAcceptCheckBox(!acceptCheckBox)
      }

      const closeModal = () => {
        Modal.close()
      }

      const clearState = () => {
        setAcceptCheckBox(false)
        setShowCheckbox(false)
      }

      return {
        acceptCheckBox,
        showCheckbox,
        handleCheckbox,
        acceptConsent,
        consentRule,
        closeModal,
        onSubmit,
        check: isButtonPressed,
      }
    },
  ),
)

export const ConsentModal = enhancer(ConsentComponent)
